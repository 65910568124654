.ant-card {
  width: 100% !important;
  margin: 20px 0;
}

.single-memeber {
  height: 64px;
  background-color: white;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-right: 20px;

  .member-name {
    padding: 20px;
  }
}
