.pikett-wizzard-container {
  margin-top: 20px;
}
.steps-action {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row-reverse;
  justify-content: space-between;

  .ant-btn {
    margin-top: 20px !important;
  }
}
