.ant-layout-header {
  padding: 0;
  background-color: #282c34;
  display: flex;
  justify-content: center;

  .header-container {
    width: 100%;
    max-width: 1200px;
    background-color: #282c34;
    color: white;
  }
}
