.selection-container {
  height: 64px;
  background-color: white;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;

  .year-input,
  .canton-input {
    width: 30%;
    height: max-content;
  }
}
