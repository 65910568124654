.App {
  text-align: center;
  height: 100vh;
  width: 100vw;

  .ant-layout {
    min-height: 100vh;

    .ant-layout-content {
      padding: 0;
      display: flex;
      justify-content: center;
      padding-top: 20px;

      .content-container {
        width: 100%;
        height: auto;
        height: auto;
        max-width: 1200px;
      }
    }

    .ant-layout-footer {
      padding: 0;
      background-color: #282c34;
      height: 64px;

      .footer-container {
        width: 100%;
        height: 100%;
        max-width: 1200px;
        color: white;
        display: flex;
        justify-content: center;
      }
    }
  }
}
